<script setup lang="ts">
import type { TwoColumnTextData } from 'types/blocks';
import type { ComponentStyles } from 'types/index';

const props = defineProps<{
  data: TwoColumnTextData;
  componentStyles?: ComponentStyles;
}>();
</script>

<template>
  <BaseComponentWrapper :component-styles="componentStyles">
    <div class="container flex flex-col gap-8 overflow-hidden lg:grid lg:grid-cols-12">
      <HumbleScroll
        animation="fade lg:right"
        class="pb-3 lg:col-span-5 lg:pb-0"
      >
        <BaseRte
          v-if="props.data.leftColumn"
          :content="props.data.leftColumn"
        />
      </HumbleScroll>

      <HumbleScroll
        animation="fade lg:left"
        class="lg:col-span-5 lg:col-start-7"
      >
        <BaseRte
          v-if="props.data.rightColumn"
          :content="props.data.rightColumn"
        />
      </HumbleScroll>
    </div>
  </BaseComponentWrapper>
</template>
